* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --swiper-theme-color: #d9d9d9 !important;
  --swiper-navigation-size: 20px !important;
}

html {
  scroll-behavior: smooth;
}



body {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #112038;
  color: #FFFFFF;
  overflow-x: hidden;
}

.background {
  background: url("../public/entire-bg.png");
}

.comingsoon {
  background: url("../public/cs.svg")
}

.section-bg {
  background: url('../public/section-img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wave {
  background: url("../public/bg.svg") repeat-x;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  transition: 0.35s ease;
}

.wave:nth-of-type(2) {
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  margin-block: 0.5em;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #6361a7cc;
  border-radius: 10px;
}

#Blog .latest-blogs,
.president-card,
.domain {
  background: url("/public/section-img.png");
}

.president-card:hover>div {
  background: #182241bb;
}

.cardActive:hover {
  -webkit-box-shadow: inset 0px 0px 0px 2px rgb(226, 226, 226);
  -moz-box-shadow: inset 0px 0px 0px 2px rgb(226, 226, 226);
  box-shadow: inset 0px 0px 0px 2px rgb(226, 226, 226);
}

.centered {

  grid-column: span 2;
  max-width: 320px;
  justify-self: center;
  width: calc(350px);
}

.glassIco {
  color: #fff;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  overflow: hidden;

}

.glassIco,
.glassIco * {
  transition: 200ms;
}

#slider2 .slick-slide {
  width: 330px !important;
  margin: 0px 20px;
}

.slick-slider {
  max-height: 500px !important;
}

.img {
  width: 110px;
  height: 110px;
}

.img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  object-fit: contain;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: #6361a7cc transparent;
    scrollbar-width: thin;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -3200px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;