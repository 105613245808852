.event-card {
  transition: border-color 0.3s, transform 0.3s ease-in-out;
}

.event-card.pulsate-border {
  animation: pulsate 2s ease-in-out infinite;
  border-color: rgba(255, 255, 255, 0.5);
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
    border-color: rgba(255, 255, 255, 0.7);
  }

  100% {
    transform: scale(1);
    border-color: rgba(255, 255, 255, 0.5);
  }
}